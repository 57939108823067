import { render, staticRenderFns } from "./Cert_arch.vue?vue&type=template&id=58734744&scoped=true&"
import script from "./Cert_arch.vue?vue&type=script&lang=js&"
export * from "./Cert_arch.vue?vue&type=script&lang=js&"
import style0 from "./Cert_arch.vue?vue&type=style&index=0&id=58734744&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58734744",
  null
  
)

/* custom blocks */
import block0 from "./Cert_arch.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VOverlay,VProgressCircular,VRow})
